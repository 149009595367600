var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drPickWrap" },
    [
      _vm._m(0),
      _vm.shaEnLicenseNum && _vm.access
        ? _c("section", { staticClass: "else-wrap" }, [
            _c(
              "button",
              {
                staticClass: "btn-back",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.historyBack($event)
                  },
                },
              },
              [_vm._v(" 뒤로 ")]
            ),
            _c("div", { staticClass: "login-wrap" }, [
              _c(
                "h2",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "app-main" })
                    },
                  },
                },
                [_vm._v(" 닥터인포 ")]
              ),
              _c("div", { staticClass: "login-write" }, [
                _c("ul", [
                  _vm._m(1),
                  _c("li", [
                    _c("label", { staticClass: "hidden" }, [
                      _vm._v("비밀번호"),
                    ]),
                    _c("div", { staticClass: "text-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                          { name: "number", rawName: "v-number" },
                          { name: "focus", rawName: "v-focus" },
                        ],
                        ref: "password",
                        class: _vm.errorMessage ? "error" : "inputPassword",
                        attrs: {
                          type: "password",
                          maxlength: "6",
                          disabled: !_vm.join,
                        },
                        domProps: { value: _vm.password },
                        on: {
                          keyup: _vm.inputPassword,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.password = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "flex-lr" }, [
                      _c("div", { staticClass: "checkbox-wrap" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.isLongTerm,
                              expression: "isLongTerm",
                            },
                          ],
                          attrs: { id: "autoLogin", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.isLongTerm)
                              ? _vm._i(_vm.isLongTerm, null) > -1
                              : _vm.isLongTerm,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.isLongTerm,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.isLongTerm = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isLongTerm = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isLongTerm = $$c
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "autoLogin" } }, [
                          _vm._v("자동 로그인"),
                        ]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn-password-find",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.linkToFindPassword($event)
                            },
                          },
                        },
                        [_vm._v(" 비밀번호 찿기 ")]
                      ),
                    ]),
                  ]),
                ]),
                _vm.errorMessage
                  ? _c("div", { staticClass: "message-box" }, [
                      _c("p", [_c("i"), _vm._v(_vm._s(_vm.errorMessage))]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-account",
                    attrs: { disabled: _vm.join },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.linkToJoin($event)
                      },
                    },
                  },
                  [_vm._v(" 의사랑 계정 연결 ")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "login-txt" },
                [
                  _c("div", [
                    _c("span", [
                      _c("i", { staticClass: "ico-user" }),
                      _vm._v(
                        _vm._s(_vm.userName) +
                          "(" +
                          _vm._s(_vm.licenseNum) +
                          ")"
                      ),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-link-account",
                        attrs: { disabled: !_vm.join },
                      },
                      [
                        _vm._v(
                          " 의사랑 계정 연결 " +
                            _vm._s(_vm.join ? "완료" : "미완료") +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("copyright", [
                    _vm._v("© "),
                    _c("i", { staticClass: "logo-ubcare" }),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-bar" }, [
      _c("h1", [_vm._v("닥터인포")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "password-txt" }, [
        _vm._v(" 닥터인포 로그인을 위해"),
        _c("br"),
        _vm._v("간편 비밀번호 6자리 숫자를 입력해 주세요 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }