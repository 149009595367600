import store from '@/store'
import axios from '@axios'
import { changeUserPoint } from '@/auth/utils'
import eventBus from '@/libs/event/event-bus'

export const getUserLoginInfo = async () => {
  const loginId = store.getters['infoData/getShaEnLicenseNum']

  if (loginId === null) return

  try {
    const rs = await axios.post(`/fu/user/info/login`, { loginId })

    if (rs.data.length === 0) {
      store.commit('util/setJoin', false)
      store.commit('util/setAccess', true)
      store.commit('util/setVerify', false)
    } else {
      store.commit('util/setJoin', true)
      store.commit('util/setAccess', rs.data.accessFailedCount < 6)
      store.commit('util/setVerify', rs.data.isVerification)
    }
  } catch (err) {
    store.commit('util/setJoin', false)
    store.commit('util/setAccess', true)
    store.commit('util/setVerify', false)
  }
}

export const confirmPointMission = async (category) => {
  axios.post(`/fu/point-history/mission`, {
    category
  })
    .then(rs => {
      const { isComplete, userPoint } = rs.data

      if (isComplete) {
        changeUserPoint(userPoint)

        eventBus.$emit('notifyCount')
      }
    })
    .catch(() => {
    })
}
