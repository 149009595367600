<template>
  <div class="drPickWrap">
    <div class="title-bar">
      <h1>닥터인포</h1>
    </div>

    <section
      v-if="shaEnLicenseNum && access"
      class="else-wrap"
    >
      <button
        class="btn-back"
        @click.prevent="historyBack"
      >
        뒤로
      </button>

      <div
        class="login-wrap"
      >
        <h2
          style="cursor: pointer"
          @click="$router.push({ name: 'app-main' })"
        >
          닥터인포
        </h2>

        <div class="login-write">
          <ul>
            <li>
              <p class="password-txt">
                닥터인포 로그인을 위해<br>간편 비밀번호 6자리 숫자를 입력해 주세요
              </p>
            </li>
            <li>
              <label class="hidden">비밀번호</label>
              <div class="text-wrap">
                <input
                  ref="password"
                  v-model="password"
                  v-number
                  v-focus
                  type="password"
                  maxlength="6"
                  :class="errorMessage ? 'error' : 'inputPassword'"
                  :disabled="!join"
                  @keyup="inputPassword"
                >
              </div>
            </li>
            <li>
              <div class="flex-lr">
                <div class="checkbox-wrap">
                  <input
                    id="autoLogin"
                    v-model="isLongTerm"
                    type="checkbox"
                  >
                  <label for="autoLogin">자동 로그인</label>
                </div>
                <button
                  class="btn-password-find"
                  @click.stop="linkToFindPassword"
                >
                  비밀번호 찿기
                </button>
              </div>
            </li>
          </ul>

          <div
            v-if="errorMessage"
            class="message-box"
          >
            <p><i />{{ errorMessage }}</p>
          </div>
        </div>

        <div class="btn-wrap">
          <button
            class="btn-account"
            :disabled="join"
            @click.stop="linkToJoin"
          >
            의사랑 계정 연결
          </button>
        </div>

        <div class="login-txt">
          <div>
            <span><i class="ico-user" />{{ userName }}({{ licenseNum }})</span>
            <button
              class="btn-link-account"
              :disabled="!join"
            >
              의사랑 계정 연결 {{ join ? '완료' : '미완료' }}
            </button>
          </div>
          <copyright>&copy; <i class="logo-ubcare" /></copyright>
        </div>
      </div>
    </section>

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import { setUserData, saveRefreshToken } from '@/auth/utils'
import { mapGetters } from 'vuex'
import { getUserLoginInfo } from '@/common/userInfo/service'
import { setStatistics } from '@/common/statistics/service'

export default {
  data() {
    return {
      id: null,
      password: null,
      errorMessage: null,
      isLongTerm: true,
      isProcessing: false,
    }
  },
  computed: {
    ...mapGetters({
      infoSetting: 'infoData/getInfoSetting',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      licenseNum: 'infoData/getLicenseNum',
      hAddress: 'infoData/getHAddress',
      medicalDept: 'infoData/getMedicalDept',
      userName: 'infoData/getUserName',
      hName: 'infoData/getHName',
      join: 'util/getJoin',
      longTerm: 'util/getLongTerm',
      access: 'util/getAccess'
    }),
  },
  watch: {
    infoSetting() {
      if (this.infoSetting && this.shaEnLicenseNum === null) {
        this.showAlertDialog('의사랑에서 면허번호를 설정하셔야 로그인 하실 수 있습니다.\n면허번호를 설정해주세요.', () => {
          this.$router.push({ name: 'app-main' })
        })
      }
    },
    shaEnLicenseNum() {
      this.handling()

      if (this.access === null) getUserLoginInfo()
    },
    join() {
      this.handling()
    },
    access() {
      if (this.access === false) this.$router.push({ name: 'pw-reset-main' })
    }
  },
  created() {
    if (this.infoSetting && this.shaEnLicenseNum === null) {
      this.showAlertDialog('의사랑에서 면허번호를 설정하셔야 로그인 하실 수 있습니다.\n면허번호를 설정해주세요.', () => {
        this.$router.push({ name: 'app-main' })
      })
    } else if (this.shaEnLicenseNum !== null) {
      this.handling()

      if (this.access === null) getUserLoginInfo()
    }
  },
  methods: {
    handling() {
      if (this.join === false) {
        this.errorMessage = '의사랑 계정 연결이 필요합니다.'
      } else this.errorMessage = null
    },
    inputPassword() {
      if (this.join && this.password !== null && this.password.length === 6) {
        if (this.isProcessing === false) this.login()
      }
    },
    async login() {
      this.isProcessing = true

      useJwt.login({
        id: this.shaEnLicenseNum,
        password: this.password,
        isLongTerm: this.isLongTerm,
      })
        .then(response => {
          const { userData } = response.data

          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)

          if (userData.useLongTermToken) {
            saveRefreshToken(response.data.refreshToken)
          }

          if (userData.userName !== this.userName) userData.userName = this.userName

          setUserData(userData)

          this.updateInfo() // 의사랑 정보 업데이트
          setStatistics(100) // 100: 로그인 성공

          this.$router.replace({ name: (this.$route.query.redirect || 'app-main'), params: this.$route.params })
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.status !== 500) {
            if (error.response.data.isJoin === false) this.errorMessage = '의사랑 계정 연결이 필요합니다.'
            else if (error.response.data.isMember === false) this.errorMessage = '로그인이 불가한 회원입니다. 서비스 담당자를 통해 문의해 주세요.'
            else if (error.response.data.failCount > 5) this.linkToResetPassword()
            else this.errorMessage = `비밀번호가 일치하지 않습니다. (${error.response.data.failCount}/5)`
          } else {
            this.errorMessage = '로그인이 실패하였습니다. 잠시 후 다시 시도해 주세요.'
          }
          setStatistics(101) // 101: 로그인 실패
        }).then(() => {
          this.isProcessing = false
        })
    },
    async updateInfo() {
      axios.put('/fu/user/profile/ysarang', {
        fullName: this.userName,
        hcpWorkPlace: this.hName,
        hcpWorkAddress: this.hAddress,
        HcpMedicalDept: this.medicalDept,
      })
        .then(() => {
        })
        .catch(() => {
        })
    },
    historyBack() {
      if (this.$route.params && this.$route.params.back === 'main') {
        this.$router.push({ name: 'app-main' })
      } else if (this.$route.query && this.$route.query.redirect === 'app-survey-main') {
        // 설문에서 로그인 페이지로 넘어온 경우
        // backContinue 값이 true일 때, 뒤로가기 시 이전 작업을 이어서 진행하고 아닌 경우 설문 목록을 표시
        if (this.$route.params && this.$route.params.backContinue) this.$router.replace({ name: this.$route.query.redirect, params: this.$route.params })
        else this.$router.push({ name: this.$route.query.redirect })
      } else if (this.$route.query && this.$route.query.redirect) {
        // 넘어온 페이지에서 redirect 값이 존재하는 경우, 뒤로가기 시 리다이렉트 페이지로 이동
        this.$router.push({ name: this.$route.query.redirect, params: this.$route.params })
      } else {
        this.$router.back()
      }
    },
    linkToJoin() {
      if (this.join) this.showAlertDialog('이미 의사랑 계정 연결이 완료되었습니다.')
      else {
        const licenseNum = this.licenseNum ? this.licenseNum.trim() : null
        // 면허번호는 4~6자리 숫자로만 허용 or "D" + 6자리숫자 임의 허용
        if (!licenseNum || (/^\d{4,6}$/.test(licenseNum) === false && /^D\d{6}$/.test(licenseNum) === false)) {
          this.showAlertDialog('올바르지 않은 의사면허번호입니다.\n의사랑에 입력된 의사면허번호를 다시 확인해 주세요.')
          return
        }

        axios.post(`/fu/user/info/re-join`, {
          loginId: this.shaEnLicenseNum
        })
          .then(rs => {
            if (rs.data) {
              this.$router.push({ name: 'member-join-main' })
            } else {
              this.showAlertDialog('계정 연결 해제 후 재연결은 1달 이후에 가능합니다.')
            }
          })
          .catch(() => {
            this.showAlertDialog('재연결 여부 확인 중 오류가 발생했습니다.')
          })
      }
    },
    linkToFindPassword() {
      if (this.join) this.$router.push({ name: 'pw-find-main' })
      else this.showAlertDialog('의사랑 계정 연결이 필요합니다.')
    },
    linkToResetPassword() {
      this.$store.commit('util/setAccess', false)

      this.$router.push({ name: 'pw-reset-main' })
    }
  },
}
</script>
